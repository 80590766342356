import React from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";
import { useState } from "react";

const ComingSoon = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex flex-col gap-4 px-5 md:px-20 lg:px-40 mt-24 mb-10 mx-auto">
        <img src={iconImages.Soon} alt="" className="w-[400px] mx-auto" />

        <p className="font-[inter] font-[300] lg:text-base text-xs md:text-sm md:w-[70%] lg:w-[50%] text-center mx-auto">
          Exciting things are on the horizon! Stay tuned as we prepare to unveil
          our latest offerings. Follow us on social media or sign up for our
          newsletter to be the first to know when we launch.
        </p>

        <div className="mx-auto">
          <form
            onSubmit={handleSubmit}
            className="flex md:flex-row flex-col items-center gap-2 z-[100]"
          >
            <input
              type="email"
              placeholder="Enter Your Email..."
              className="px-5 py-2 rounded-md bg-[#F0F5EC] text-[#003C1C] focus:outline-none placeholder:text-[#003C1C] placeholder:text-sm w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="w-full md:w-fit px-5 py-[10px] bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md text-sm md:text-base">
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default ComingSoon;
