import React from "react";
import { iconImages } from "../utils/images";

const Download = () => {
  return (
    <div className="my-10 lg:mt-[200px] bg-[#F0F5EC] rounded-2xl px-4 pt-4 lg:p-14 flex flex-col lg:flex-row items-center relative shadow-md">
      <div className="lg:w-1/2 flex flex-col gap-3">
        <h3 className="text-[#141F39] font-bold text-2xl lg:text-left md:text-center">
          Download & Install Ajeoba Now and Enjoy Quality Products & Inputs
        </h3>
        <p className="text-sm lg:text-left md:text-center">
          Register with Ajeoba now to access top-quality agricultural products
          and inputs conveniently. Gain seamless transaction tracking, stay
          updated on new offerings, and receive personalized recommendations
          tailored to your needs. Elevate your farming experience today!
        </p>

        <div className="flex items-center gap-4 lg:mx-0 md:mx-auto">
          <div className="bg-[#006D33] rounded-md p-2 lg:p-3 flex items-center gap-2 w-fit">
            <img src={iconImages.Google} alt="" />
            <div className="flex flex-col">
              <p className="text-[10px] text-white">Get it on</p>
              <p className="text-[10px] md:text-sm font-semibold text-white">Google Play</p>
            </div>
          </div>

          <div className="bg-[#006D33] rounded-md p-1 lg:p-3  flex items-center gap-2 w-fit">
            <img src={iconImages.Apple} alt="" />
            <div className="flex flex-col">
              <p className="text-[10px] text-white">Download on the</p>
              <p className="text-[10px] text-sm font-semibold text-white">App Store</p>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:absolute right-20 bottom-0 lg:mt-0 mt-10">
        <img src={iconImages.Phones} alt="phones" className="lg:w-[400px] md:w-[300px]" />
      </div>
    </div>
  );
};

export default Download;
