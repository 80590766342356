// content.js

//static imports
import logoImage from '../assets/images/logo.png';
import menuBarImage from '../assets/images/menubar.png'
import pt1 from '../assets/images/Home/Partners/pt1.png'
import pt2 from '../assets/images/Home/Partners/pt2.png'
import pt3 from '../assets/images/Home/Partners/pt3.png'
import pt4 from '../assets/images/Home/Partners/pt4.png'
import pt5 from '../assets/images/Home/Partners/pt5.png'
import pt6 from '../assets/images/Home/Partners/pt6.png'
import pt7 from '../assets/images/Home/Partners/pt7.png'
import pt8 from '../assets/images/Home/Partners/pt8.png'

//HomePage
import Hero from '../assets/images/Home/Hero/heropng.png'

//navContent
const navLinks = [
//   { id: 1, title: 'Home', path: '/'},
  { id: 2, title: 'About Us', path: '/about'},
  { id: 3, title: 'Marketplace', path: '/marketplace'},
  { id: 4, title: 'Update', path: '/update'},
//   { id: 5, title: 'Marketplace', path: '/marketplace'},
  { id: 6, title: 'Community', path: '/community'}, 
  { id: 7, title: 'Contact Us', path: '/contactus'}
  // Add more navigation items as needed
];

const logo = logoImage;
const menuBar = menuBarImage;

//HOMEPAGE 
//Hero Text
const heroText = {
  title: 'Agriculture Made Smarter With Digital Infrastructure.',
  subTitle: 'We aim to revolutionize how agricultural products are grown, traded, financed, and delivered in Africa through innovative technology.',
  btnLabel: 'Get started',
  HeroImg: Hero
}

const partners = {
  partner1: pt1,
  partner2: pt2,
  partner3: pt3,
  partner4: pt4,
  partner5: pt5,
  partner6: pt6,
  partner7: pt7,
  partner8: pt8 
}

export { navLinks, logo, menuBar, heroText, partners};

