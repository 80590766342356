import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { iconImages } from "../utils/images";
import UpdatesItem from "../components/UpdatesItem";

const Update = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mt-24">
        <div className="flex flex-col w-full relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.About} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-center gap-5 lg:w-[55%] order-1 lg:order-2 mx-auto">
            <p className="text-[#141F39] font-bold text-[30px] md:text-[40px] lg:text-4xl">
              Our Updates
            </p>
            <p className="font-[inter] text-[#4F4F4F] font-[400]  text-xs md:text-sm text-center">
              Stay informed and inspired with Ajeoba's latest updates. Explore
              insightful blogs, breaking news, and industry highlights curated
              to empower your agricultural journey.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
          <UpdatesItem
            id={1}
            name={"Deborah"}
            date={"Oct 10th, 2023"}
            from="update"
            title="Unlocking Africa's Agricultural Potential: Market Trends and Analysis"
            description="The agricultural market in Africa is expected to reach a value of $1 trillion by 2025, signaling significant growth opportunities..."
            content={`
              The agricultural market in Africa is expected to reach a value of $1 trillion by 2025, signaling significant growth opportunities. At Ajeoba, we are dedicated to empowering smallholder farmers and consumers.

              Here are some of the key market trends:

              1. Increasing demand for organic and specialty crops.
              2. Rise in the use of digital agriculture technologies.
              3. Expansion of the market for climate-resilient agriculture.

              At Ajeoba, we focus on the following areas:
              ^ Conducting market research and analysis.
              ^ Providing training programs for farmers.
              ^ Ensuring access to high-quality inputs.
              ^ Developing digital agriculture platforms.
              ^ Implementing climate-resilient agriculture strategies.

              These initiatives benefit both farmers and consumers by leading to improved crop yields, enhanced food security, increased income, access to quality products, and sustainable agricultural practices.

              Don't miss out on these opportunities - join Ajeoba's community to stay informed about market trends and innovative solutions.
            `}
            icon={iconImages.Map}
          />

          <UpdatesItem
            id={2}
            name={"Funmilola"}
            from="update"
            date={"Oct 14th, 2023"}
            title="Healthy Eating Made Easy: How to Choose Fresh, Sustainable Produce"
            description="As a health-conscious consumer, you should prioritize fresh, nutritious produce for your family. But with so many options available, how do you make informed choices..."
            content={`
              As a health-conscious consumer, you should prioritize fresh, nutritious produce for your family. But with so many options available, how do you make informed choices?

              Ajeoba can help you make informed choices, but before we delve into how Ajeoba can do this, what are the benefits of sustainable produce?

              1. Better taste and nutrition
              2. Reduced environmental impact
              3. Supporting local economies
              4. Improved food safety

              Now this is where Ajeoba's Solution comes in, our platform connects consumers with local farmers, ensuring; Freshness and quality, transparency and traceability, seasonal and diverse produce options, competitive pricing.

              Are you asking yourself why you should choose Ajeoba?

              1. We have a convenient online ordering
              2. Direct support for local farmers
              3. Access to expert advice on healthy eating
              4. Community engagement and education

              Join Ajeoba's community today and start enjoying fresh and sustainable produce!
              `}
            icon={iconImages.Agric}
          />

          {/* <UpdatesItem
            id={3}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />

          <UpdatesItem
            id={4}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={5}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={6}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />

          <UpdatesItem
            id={7}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={8}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={9}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          /> */}
        </div>

        <button className="px-5 py-[10px] md:px-12 md:py-4 border border-[#006D33] w-fit flex items-center justify-center gap-2 mx-auto mb-10 rounded-lg focus:outline-none">
          <p className="text-[#006D33] font-semibold text-sm md:text-base">
            Load More
          </p>
          <img src={iconImages.MarketArrow} alt="" className="" />
        </button>
      </div>

      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default Update;
