import React from "react";

const ValueTwoItem = ({icon, title, desc}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 bg-[#F0F5EC] p-5 w-full md:w-1/2 sm:w-full rounded-xl shadow-sm">
      <img
        src={icon}
        className="w-[170px] rounded-t-xl"
        alt=""
      />

      <h4 className="text-sm font-[600] font-[inter] w-[70%] text-center text-[#006D33]">
        {title}
      </h4>

      <p className="text-xs font-[inter] text-center w-full">
        {desc}
      </p>
    </div>
  );
};

export default ValueTwoItem;
