import React, { useState } from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";
import Product from "../components/Product";

const Marketplace = () => {
  const [filter, setFilter] = useState("all");

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mt-24">
        <div className="flex flex-col lg:flex-row items-center justify-between relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.About} alt="" className="w-full h-full" />
          </div>
          <div className="w-[70%] lg:w-[50%] relative lg:left-7 order-2 lg:order-1 mt-10 lg:mt-0">
            <img
              src={iconImages.Explore}
              className="w-[400px] animate-pulse"
              alt=""
            />
          </div>

          <div className="flex flex-col gap-2 lg:gap-5 lg:w-[50%] order-1 lg:order-2">
            <p className="text-[#141F39] font-bold text-[30px] md:text-[40px] lg:text-4xl">
              Our Marketplace
            </p>
            <p className="font-[inter] font-[300] lg:text-base text-left text-xs md:text-sm">
              At our marketplace, we're not just selling farm products and
              inputs; we're offering you a pathway to seamless transactions
              within the value chain. We connect buyers and sellers to a robust
              and interactive B2B/B2C digital storefront for quality
              agricultural produce and inputs. With top-notch payment settlement
              services and real-time order/delivery tracking, we ensure seamless
              transactions for all.
            </p>
            <div className="flex items-center gap-6">
              <button className="mt-2 lg:mt-0 px-5 py-[10px] md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md md:rounded-xl text-sm md:text-base flex items-center gap-2">
                <p>Scroll down</p>
                <img src={iconImages.ArrowDown} alt="" />
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-20 mb-10 gap-10">
          <div className="flex items-center justify-between">
            <div className="basis-[100%] md:basis-[45%] relative">
              <input
                type="text"
                placeholder="Search for products and inputs..."
                className="focus:outline-none py-2 px-8 border border-[#9F9F9F] rounded-md placeholder:text-[#9F9F9F] w-full placeholder:text-xs md:placeholder:text-base"
              />

              <img
                src={iconImages.MarketSearch}
                alt=""
                className="absolute left-2 top-[11px]"
              />
            </div>

            <div className="p-[10px] md:p-3 bg-[#006D33] flex items-center  gap-2 w-fit rounded-md cursor-pointer right-5 md:right-0 absolute md:relative">
              <img src={iconImages.Filter} alt="" />
              <p className="font-semibold text-white text-sm">Filter</p>
            </div>
          </div>

          <div className="flex items-center justify-around gap-2 overflow-x-scroll scrollbar-none">
            <div
              className={`py-2 px-5 ${
                filter === "all"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("all")}
            >
              <p className="font-semibold text-[11px]">All</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "grains"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("grains")}
            >
              <p className="font-semibold text-[11px]">Grains</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "live"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("live")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Live Stocks
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "fruits"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("fruits")}
            >
              <p className="font-semibold text-[11px]">Fruits</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "vegetables"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("vegetables")}
            >
              <p className="font-semibold text-[11px]">Vegetables</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "seeds"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("seeds")}
            >
              <p className="font-semibold text-[11px]">Seeds</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "fertilizers"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("fertilizers")}
            >
              <p className="font-semibold text-[11px]">Fertilizers</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "vaccines"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("vaccines")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Vaccines & Medicines
              </p>
            </div>

            <div className="py-2 px-5 bg-[#FFFF] flex items-center w-fit rounded-md cursor-pointer">
              <img src={iconImages.MarketArrow} alt="" className="" /> {""}
            </div>
          </div>

          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
          </div>
        </div>

        <button className="px-5 py-[10px] md:px-12 md:py-4 border border-[#006D33] w-fit flex items-center justify-center gap-2 mx-auto mb-10 rounded-lg focus:outline-none">
          <p className="text-[#006D33] font-semibold text-sm md:text-base">Load More</p>
          <img src={iconImages.MarketArrow} alt="" className="" />
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Marketplace;
