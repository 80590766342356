import React, { useState } from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";

const FAQs = [
  {
    id: "1",
    question: "What products do you offer on your platform?",
    answer:
      "We provide a wide range of inputs, including but not limited to seeds, fertilizers, pesticides, and agricultural equipment. We also offer digital infrastructure solutions that support the agricultural value chain.",
  },
  {
    id: "2",
    question: "How can I place an order?",
    answer:
      "Simply download our app, browse through the available products, and add them to your cart. Proceed to checkout and follow the steps to complete your order.",
  },
  {
    id: "3",
    question: "Is it safe to make payments on your app?",
    answer:
      "Yes, we prioritize the security of your transactions. Our app uses secure payment gateways to ensure the safety of your financial information.",
  },
  {
    id: "4",
    question:
      "What payment methods do you accept?",
    answer:
      "We accept various payment methods, including credit/debit cards, net banking, and mobile wallets. You can choose the option that suits you best during checkout.",
  },
  {
    id: "5",
    question: "Can I track my order?",
    answer:
      "Yes, you can track your order in real-time through the app. We provide regular updates on the status of your delivery.",
  },
  {
    id: "6",
    question: "Do you offer international shipping?",
    answer:
      "Currently, we only provide services within Nigeria. We do not offer international shipping at this time.",
  },
  {
    id: "7",
    question: "How do I contact customer support?",
    answer:
      "You can reach our customer support team through the 'Contact Us' section in the app. Alternatively, you can email us at admin@ajeoba.com or call +2349070534510.",
  },
  {
    id: "8",
    question: "Are the products on your platform authentic and of high quality?",
    answer:
      "We source our products from reputable suppliers to ensure authenticity and quality. If you ever receive a product that does not meet your expectations, please contact our customer support for assistance.",
  },
  {
    id: "9",
    question: "Do you provide bulk purchasing options for businesses?",
    answer:
      "Yes, we offer bulk purchasing options for businesses. Please contact our corporate sales team through the app or website for customized solutions.",
  },
  {
    id: "10",
    question: "Do you offer any digital services for agribusinesses?",
    answer:
      "Yes, we offer digital solutions to help agribusinesses streamline their operations, manage supply chains, and connect with farmers. These solutions include online marketplaces, data analytics, and financial services integration.",
  },
  {
    id: "11",
    question: "How do you support smallholder farmers on your platform?",
    answer:
      "We provide smallholder farmers access to affordable inputs, market information, and digital tools to enhance their productivity. We also connect them with buyers through our marketplace.",
  },
  {
    id: "12",
    question: "How do I sign up and onboard on the platform?",
    answer:
      "To sign up, download our app and click on the 'Sign Up' button. Provide the required details such as your name, phone number, and email address. You will receive an OTP (One-Time Password) for verification. Once verified, you can complete your profile and start using the platform.",
  },
];

const Faq = () => {
  const [filter, setFilter] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] lg:mb-24 lg:mt-[130px]">
        <div className="flex flex-col gap-5">
          <p className="text-[#040405] font-bold text-[30px] md:text-[40px] lg:text-4xl">
            Help Center
          </p>

          <div className="flex items-center justify-around gap-2 overflow-x-scroll scrollbar-none">
            <div
              className={`py-2 px-5 ${
                filter === "all"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("all")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">All</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Farming Association"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Farming Association")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Farming Association
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Input Supplier"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Input Supplier")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Input Supplier
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Aggregator"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Aggregator")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Aggregator
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Corporate Buyer"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Corporate Buyer")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Corporate Buyer
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Quality Assurance"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Quality Assurance")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Quality Assurance
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Logistics"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Logistics")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Logistics
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            {FAQs.map((faq) => {
              return (
                <div
                  key={faq.id}
                  className={`${
                    isOpen && selectedId === faq.id
                      ? "bg-[#006D33]"
                      : "bg-[#F0F5EC]"
                  } p-5 md:p-7 rounded-lg flex items-start justify-between gap-5 md:gap-0`}
                >
                  <p
                    className={`basis-[5%] font-bold text-xl md:text-base lg:text-lg ${
                      isOpen && selectedId === faq.id
                        ? "text-white"
                        : "text-[#003C1C]"
                    }`}
                  >
                    {faq.id.length < 2 ? `0${faq.id}` : faq.id}
                  </p>

                  <div className="basis-[85%] flex flex-col gap-2">
                    <p
                      className={`${
                        isOpen && selectedId === faq.id
                          ? "text-white"
                          : "text-[#003C1C]"
                      } text-xs md:text-sm lg:text-base font-medium mt-1`}
                    >
                      {faq.question}
                    </p>
                    {isOpen && selectedId === faq.id && (
                      <p
                        className={`${
                          isOpen && selectedId === faq.id
                            ? "text-white"
                            : "text-[#003C1C]"
                        } text-[10px] md:text-xs lg:text-sm`}
                      >
                        {faq.answer}
                      </p>
                    )}
                  </div>

                  <div className="basis-[5%] flex">
                    {selectedId === faq.id ? (
                      <div
                        className="w-5 h-5 md:h-7 md:w-7 bg-white flex items-center justify-center rounded-full cursor-pointer ml-auto"
                        onClick={() => {
                          setIsOpen(false);
                          setSelectedId(null);
                        }}
                      >
                        <img src={iconImages.Times} alt="" className="w-10" />
                      </div>
                    ) : (
                      <div
                        className="w-5 h-5 md:h-7 md:w-7 bg-white flex items-center justify-center rounded-full cursor-pointer ml-auto"
                        onClick={() => {
                          setSelectedId(faq.id);
                          setIsOpen(true);
                        }}
                      >
                        <img
                          src={iconImages.Add}
                          alt=""
                          className="w-4 md:w-5"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default Faq;
