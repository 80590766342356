import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { iconImages } from "../utils/images";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

  const navigate = useNavigate()

  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex flex-col gap-4 px-5 md:px-20 lg:px-40 mt-24 mb-10 mx-auto">
        <img src={iconImages.Notfound} alt="" className="w-[400px] mx-auto" />

        <p className="font-[inter] font-[300] lg:text-base text-xs md:text-sm md:w-[50%] text-center mx-auto">
          Return to our homepage or navigation links to discover the bounty of
          information and resources Ajeoba has to offer. Happy exploring
        </p>

        <button className="px-5 py-[10px]  cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md md:rounded-xl text-sm md:text-base flex items-center gap-2 w-fit mx-auto" onClick={() => navigate(-1)}>
          <p>Go Back</p>
          <img src={iconImages.Arrow} alt="" className="w-3 md:w-5" />
        </button>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
