import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const Support = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center my-8">Contact Support</h1>
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Customer Support</h2>
          <p className="mb-2">Person 1: <a href="tel:+2348168604604" className="text-blue-500 hover:underline">+234 816 860 4604</a></p>
          <p>Person 2: <a href="tel:+2348168579877" className="text-blue-500 hover:underline">+234 816 857 9877</a></p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">Social Media Handles</h2>
          <p className="mb-2">LinkedIn: <a href="https://www.linkedin.com/company/ajeobaex/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Ajeobaex</a></p>
          <p>Facebook: <a href="https://web.facebook.com/profile.php?id=100022089145982" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Profile</a></p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Support

