import React from "react";

const ValueOneItem = ({ icon, title, desc }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 bg-[#F0F5EC] p-10 md:p-3 lg:p-10 rounded-xl w-[100%]  shadow-sm">
      <div className="rounded-full w-20 h-20 flex items-center justify-center bg-white">
        <img src={icon} className="w-12" alt="" />
      </div>

      <h4 className="text-sm font-[600] font-[inter] w-[70%] text-center">
        {title}
      </h4>

      <p className="text-sm font-[inter] text-center">{desc}</p>

      <div className="bg-[#090909] h-[2px] w-[30%] mt-2"></div>
    </div>
  );
};

export default ValueOneItem;
