import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] lg:mb-24 lg:mt-[150px]">
        <div className="flex flex-col gap-5">
          <p className="text-[#040405] font-bold text-[30px] md:text-[40px] lg:text-4xl">
            Privacy Policy
          </p>

          <div className="flex flex-col gap-3 font-[inter] font-[400] text-[10px] md:text-xs text-[#808080]">
            <strong className="text-[#040405] font-semibold">
              1. Introduction
            </strong>

            <strong className="text-[#040405] font-semibold">
              1.1 Privacy Policy
            </strong>

            <p className="ml-[11px]">
              We are committed to safeguarding the privacy of our website
              visitors and respecting their right to data privacy. As such, this
              Privacy Policy (“Privacy Policy”) applies to information we
              collect or use on websites, including https://web.ajeoba.com (the
              Agro-Exchange) and any products, features, services, or any
              portion thereof (collectively, the “Service”), owned or controlled
              by Ajeoba Agro-Exchange Limited, or its affiliated companies,
              which are entities that control, are controlled by or are under
              common control with us (“Affiliates”) (collectively, the “Company”
              or “we” or “our” or “us”).
            </p>

            <strong className="text-[#040405] font-semibold">
              1.2 Changes to Privacy Policy
            </strong>

            <p className="ml-[11px]">
              Although most changes are likely to be minor, we may change our
              Privacy Policy from time to time, and in our sole discretion. We
              encourage visitors to frequently check this page for any changes
              to the Privacy Policy. Your continued use of the Service after any
              change in this Privacy Policy will constitute your acceptance of
              the changes. The date the privacy policy was last revised is
              identified at the top of the page. You are responsible for
              ensuring we have an up-to-date active and deliverable e-mail
              address for you and for periodically visiting this privacy policy
              to check for any changes.
            </p>

            <strong className="text-[#040405] font-semibold">
              1.3 Terms of Service
            </strong>

            <p className="ml-[11px]">
              This Privacy Policy and our Terms of Service (the “Terms of
              Service”), which is incorporated herein by reference, are an
              agreement (collectively, this “Agreement”) between you (“You” or
              “your” or “you”), a user of the Service, and us. By using our
              website, you acknowledge and agree to this policy, and consent to
              our use of cookies in accordance with the terms of this Agreement.
            </p>

            <strong className="text-[#040405] font-semibold">
              1.4 Third-Party Links
            </strong>

            <p className="ml-[11px]">
              The Service contains third party links to other websites or
              services. Please be aware that we are not responsible for the
              content or privacy practices of such other third- party websites
              or services. We encourage our users to be aware when they leave
              the Service and to read the privacy statements of any other site
              that collects personal information. When you use the Service or
              its content, certain third parties may use automatic information
              collection technologies to collect information about you or your
              device. These third parties may include:
              <ul className="ml-10 leading-6">
                <li>● Advertisers, ad networks and ad servers;</li>
                <li>● Analytics companies;</li>
                <li>● Your mobile device manufacturer;</li>
                <li>● Your mobile service provider.</li>
              </ul>
              These third parties may use tracking technologies to collect
              information about you when you use the Service. The information
              they collect may be associated with your personal information or
              they may collect information, including personal information,
              about your online activities over time and across different
              websites, apps and other online services websites. They may use
              this information to provide you with interest based (behavioral)
              advertising or other targeted content. We do not control these
              third parties' tracking technologies or how they may be used. If
              you have any questions about an advertisement or other targeted
              content, you should contact the responsible provider directly.
            </p>
          </div>

          <div className="flex flex-col gap-3 font-[inter] font-[400] text-[10px] md:text-xs text-[#808080]">
            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                2. Information We Collect
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  2.1 We may collect, store and use the following kinds of
                  personal information:
                </p>{" "}
                <p className="ml-[11px]">
                  (a) information about your computer and about your visits to
                  and use of the Service including your Internet Protocol (IP)
                  address, geographical location, browser type and version,
                  devise characteristics, operating system, language
                  preferences, referral source, and information on action taken
                  on the Site, such as; length of visit, page views and website
                  navigation paths;
                </p>{" "}
                <p className="ml-[11px]">
                  (b) information that you provide to us when registering with
                  the Service including your email address;
                </p>
                <p className="ml-[11px]">
                  (c) information that you provide or that is collected by and
                  from Facebook, Twitter, Instagram or other social-media
                  properties, whether existing now or in the future, when
                  completing your profile on the Service including your name,
                  profile pictures, gender, date of birth, relationship status,
                  interests and hobbies, educational details, employment details
                  and other personal data or information;
                </p>
                <p className="ml-[11px]">
                  (d) information that you provide to us for the purpose of
                  subscribing to our email notifications and/or newsletters or
                  text message alerts, or participate in one of our online
                  forums or communities including your name, postal address,
                  email address, telephone and/or mobile numbers;
                </p>{" "}
                <p className="ml-[11px]">
                  (e) information that you provide to us when using the Service,
                  or that is generated in the course of the use of those
                  services including the timing, frequency and pattern of
                  service use;
                </p>
                <p className="ml-[11px]">
                  (f) information relating to any purchases you make of our
                  goods and/or services or any other transactions that you enter
                  into through the Service including your name, address,
                  telephone number, email address, fax number, and credit card
                  details;
                </p>
                <p className="ml-[11px]">
                  (g) content that you submit to the Service or via links to
                  social media networks, forums, blogs, message boards, chat
                  rooms or similar functionality, including audio recordings,
                  software code, videos, photos, images, text, information
                  (including, without limitation, personal information), user
                  comments, and any other content (collectively, “User Content”)
                </p>
                <p className="ml-[11px]">
                  (h) information contained in or relating to any communication
                  with us or our third-party service providers for customer
                  support or other communications, including, without
                  limitation, records and copies of correspondence and responses
                  to surveys for research purposes;
                </p>
                <p className="ml-[11px]">
                  (j) Information from and about the devices you use to access
                  the Services, including attributes such as the operating
                  system, hardware version, device settings, file and software
                  names, types, battery and signal strength, device identifiers,
                  device locations, specific geographic locations (such as GPS,
                  Bluetooth, WiFi signals), connection information such as the
                  name of your mobile operator or ISP, browser type, language,
                  time zone, mobile phone number and IP address.
                </p>
                <p className="ml-[11px]">
                  (k) In connection with your visit or use of the Service, we
                  may automatically collect information about our users, such as
                  the numbers and frequency of users and their characteristics
                  and information about similar groups of users, certain age
                  groups or users that live in a particular geographical area.
                  This data is only used in the aggregate as a statistical
                  measure and not in a manner that would identify you
                  personally. Aggregate information generally is collected
                  through the use of cookies and beacons;
                </p>
                <p className="ml-[11px]">
                  (l) When you access or use the Service, or any portion
                  thereof, we may collect physical location information that is
                  sufficiently precise to allow us to locate a specific person
                  or device (“Location Information”). We only collect Location
                  Information with your permission (e.g., such as when you agree
                  to provide it to us after you initially access or use the
                  Service). We may use Location Information to provide you with
                  content and services that are relevant to a particular
                  location, such as advertising, search results, and directions;
                </p>
                <p className="ml-[11px]">
                  (m) The Service may access metadata and other information
                  associated with other files stored on your device. This may
                  include, for example, photographs, audio and video clips,
                  personal contacts and address book information;
                </p>
                <p className="ml-[11px]">
                  and (n) any other personal information that you choose to send
                  to us.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <div className="ml-[11px] space-y-2">
                <p>
                  2.2 We may collect, store and use information obtained by
                  automated means through the Service such as, cookies, web
                  beacons, web server logs, JavaScript and similar technologies,
                  including technologies designed to obtain information
                  regarding your use of the Service:
                </p>{" "}
                <p className="ml-[11px]">
                  (a) “Cookies” are alphanumeric identifiers that we transfer to
                  your computer’s hard drive through your Web browser to enable
                  our systems to recognize your browser and tell us how and when
                  pages in the Service are visited and by how many people. We
                  may use both session Cookies (which expire once you close your
                  web browser) and persistent Cookies (which stay on your
                  computer until you delete them) to provide you with a more
                  personal and interactive experience on our Website The “Help”
                  portion of the toolbar on the majority of browsers will direct
                  you on how to prevent your browser from accepting new cookies,
                  how to command the browser to tell you when you receive a new
                  cookie, or how to fully disable cookies. We recommend that you
                  leave the cookies activated because cookies allow you to use
                  many features of the Service. For more information, please
                  refer to Section 5.2 of the Privacy Policy.
                </p>{" "}
                <p className="ml-[11px]">
                  (b) “Web Beacons,” also known as Internet tags, pixel tags,
                  single-pixel GIFS or clear GIFs, link web pages to web servers
                  and their cookies. Web Beacons can be embedded in web pages,
                  videos, or emails, to collect certain types of information
                  from your browser, check whether you have viewed a particular
                  web page or email message, and determine, among other things,
                  the time and date on which you viewed the content, the IP
                  address of your computer, and the Uniform Resource Locator
                  (URL) of the web page from which the content was viewed. We
                  may use this information to reduce or eliminate messages sent
                  to a user.
                </p>
                <p className="ml-[11px]">
                  (c) “Web Server Logs,” including browser types, Internet
                  service providers (ISPs), referring/exit pages, platform
                  types, date/time stamps, number of clicks, and IP addresses.
                  An IP address is a number that is automatically assigned to
                  your computer whenever you access the Internet, which web
                  servers use to identify where to send the information your
                  computer requests. We may use IP addresses for a number of
                  purposes, such as system administration, to report aggregate
                  information to our business partners, or to audit the use of
                  our Services.
                </p>
                <p className="ml-[11px]">
                  (d) “Local Shared Objects,” sometimes known as Flash cookies,
                  may be used to store your preferences or display content based
                  upon what you have viewed on various websites to personalize
                  your visit.
                </p>{" "}
                <p className="ml-[11px]">
                  (e) “Ad IDs” and Other In-App Tracking Methods. There are a
                  variety of tracking technologies that may be included in
                  mobile applications, and these are not browser based like
                  cookies and cannot be controlled by browser settings. Some use
                  device identifiers, or other identifiers such as “Ad IDs” to
                  associate app user activity to a particular app.
                </p>
                <p className="ml-[11px]">
                  (f) Google Analytics and DoubleClick. We use Google Analytics
                  (“Google Analytics”), a web analytics service provided by
                  Google Inc. (“Google”). Google Analytics uses “cookies.” The
                  information generated by the cookies about your use of the
                  Service (including your IP address) will be transmitted to and
                  stored by Google on servers in the United States. Google will
                  use this information for the purpose of evaluating your use of
                  the Service, compiling reports on website activity for website
                  operators and providing other services relating to website
                  activity and internet usage. Google may also transfer this
                  information to third parties where required to do so by law,
                  or where such third parties process the information on
                  Google's behalf. Google will not associate your IP address
                  with any other data held by Google. You may refuse the use of
                  cookies by selecting the appropriate settings on your browser,
                  however, please note that if you do this, you may not be able
                  to use the full functionality of the Service. More information
                  about how you can opt-out is in Section 5 (Your Rights) below.
                </p>
                <p className="ml-[11px]">
                  By using the Service, you consent to the processing of data
                  about you by Google in the manner and for the purposes set out
                  above. We also use the services like Google DoubleClick in
                  conjunction with Google AdSense on our service. To disable
                  this cookie, visit Google’s webpage on the subject for further
                  instructions: http://www.google.com/policies/privacy/ads/.
                </p>
              </div>

              <div className="ml-[11px] space-y-2">
                <p>
                  2.3 In using some features of the Service, you may also be
                  able to submit information about other people. Before you
                  disclose to us the personal information of another person, you
                  must obtain that person’s consent, and you hereby represent
                  that you have obtained such consent from such person, to both
                  the disclosure and the processing of that personal information
                  in accordance with this Privacy Policy.
                </p>{" "}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                3. How We Use Your Information
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  3.1 Personal information submitted to us through our website
                  will be used for the purposes specified in this Privacy Policy
                  or on the relevant pages of the Service.
                </p>{" "}
                <p>3.2 We may use your personal information to:</p>{" "}
                <p className="ml-[11px]">
                  (a) To provide, operate, maintain, improve, and promote the
                  Service, and any other information, products or services that
                  you request from us;
                </p>{" "}
                <p className="ml-[11px]">
                  To enable you to access and use the Service, including
                  uploading, downloading, collaborating and sharing content,
                  photos, images, videos and information, with other users or
                  third parties;
                </p>
                <p className="ml-[11px]">
                  (c) To process, verify, and complete transactions, and send
                  you related information, including purchase confirmations and
                  invoices, to carry out our obligations and enforce our rights
                  arising from any contracts entered into between you and us,
                  including for billing, collection and enforcement, and to
                  build a user database;
                </p>
                <p className="ml-[11px]">
                  (d) To send transactional messages, including responding to
                  your comments, questions, and requests; providing customer
                  service and support; sending you technical notices, updates,
                  security alerts, and support and administrative messages
                  sending statements,
                </p>{" "}
                <p className="ml-[11px]">
                  invoices and payment reminders to you, and collecting payments
                  from you; and, when we have location information, we use it to
                  tailor our Service for you and others.
                </p>
                <p className="ml-[11px]">
                  (e) To send promotional communications, such as providing you
                  with information about services, features, newsletters,
                  offers, promotions, contests, events and sending updates;
                </p>
                <p className="ml-[11px]">
                  (f) To monitor and analyze trends, usage, and activities in
                  connection with the Service and for marketing, advertising,
                  administrative, analytical, research, optimization, and other
                  purposes. For example, we may use statistical analyses of
                  usage data in order to measure interest in the various areas
                  of the Service, for development purposes and to inform
                  advertisers and partners in aggregate and anonymous terms
                  about consumers who click on their advertising or other
                  messages. IP addresses are also used for systems
                  administration and troubleshooting purposes.
                </p>
                <p className="ml-[11px]">
                  (g) To investigate and prevent fraudulent transactions,
                  unauthorized access to the Service, and other illegal
                  activities;
                </p>
                <p className="ml-[11px]">
                  (h) To personalize the Service’s content, features or
                  advertisements;
                </p>
                <p className="ml-[11px]">
                  (j) To deal with inquires and complaints made by or about you
                  relating to the Service;
                </p>
                <p className="ml-[11px]">
                  (k) To verify compliance with the terms and conditions
                  governing the use of the Service; and
                </p>
                <p className="ml-[11px]">
                  (l) To comply with and enforce applicable legal requirements,
                  relevant industry standards, contractual obligations and our
                  policies.
                </p>
                <p>
                  3.3 If you submit personal information, including User
                  Content, for publication on the Service, we will publish and
                  otherwise use that information in accordance with the license
                  you grant to us, and by submitting such information to us, you
                  hereby grant us a worldwide, revocable, sublicensable and
                  royalty- free license to use such personal information for our
                  business purposes. You shall be solely responsible for your
                  own User Content and the consequences of submitting and
                  publishing your User Content on the Service. You further agree
                  that you will not submit to the Service any User Content or
                  other material that is contrary to this Privacy Policy or
                  contrary to applicable local, national, and international laws
                  and regulations.
                </p>{" "}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                4. Disclosing Personal Information.
              </p>
              <div className="ml-[11px] space-y-2">
                <p>4.1. Service Providers.</p>{" "}
                <p>
                  We engage service providers to perform functions and provide
                  services to us. We may share your private personal information
                  with such service providers subject to obligations consistent
                  with this Privacy Policy and any other appropriate
                  confidentiality and security measures, and on the condition
                  that the third parties use your private personal data only on
                  our behalf and pursuant to our instructions.
                </p>
                <p>4.2. Authorized Personnel.</p>{" "}
                <p>
                  Our employees, agents, consultants, contractors, or other
                  authorized personnel, including those of our contractors,
                  service providers, subsidiaries and affiliates, may have
                  access to user information as necessary in the normal course
                  of our business.
                </p>{" "}
                <p>4.3. Business Transfers.</p>{" "}
                <p>
                  In some cases, we may choose to buy or sell assets, or have
                  engaged in discussions with a buyer or other successor in the
                  event of a merger, divestiture, restructuring, reorganization,
                  dissolution or other sale or transfer of some or all of our
                  assets, whether as a going concern or as part of bankruptcy,
                  liquidation or similar proceeding, in which personal
                  information held by us about our Service users is among the
                  assets transferred. In these types of transactions, user
                  information is typically one of the businesses assets that is
                  transferred. Moreover, if the Service, the Company, or
                  substantially all of its assets, were acquired, liquidated, or
                  dissolved, personal information would be one of the assets
                  that is transferred.
                </p>{" "}
                <p>4.4. Government, Law Enforcement or Third Parties.</p>{" "}
                <p>
                  We may disclose any information, including, without
                  limitation, Personal Information that we deem necessary, in
                  our sole discretion and without your prior permission, to
                  comply with any applicable law, regulation, legal process or
                  governmental request. We also may exchange information,
                  including, without limitation, Personal Information, with
                  other companies and organizations to protect the rights,
                  property, or safety of the Company and its affiliates,
                  personnel, users, third parties, or others. We reserve the
                  right to disclose a user’s Personal Information if we believe,
                  in good faith, that the user is in violation of the Terms of
                  Service, even without a subpoena, warrant or other court
                  order.
                </p>{" "}
                <p>4.5. Social Media Networks.</p>{" "}
                <p>
                  We may include applications or widgets from social media
                  networks that allow interaction or content sharing by their
                  users. These widgets, such as a Facebook “Share” or “Like”
                  button, are visible to you on the web page you visit.
                  Integration between the Service and social media networks such
                  as Facebook, Twitter and others may allow social media
                  networks in which you participate to collect information about
                  you, even when you do not explicitly activate the network’s
                  application or widget. Please visit the applicable social
                  media network’s privacy policy to better understand their data
                  collection practices and choices they make available to you.
                  The privacy policy of the social media network controls the
                  collection, use and disclosure of all personal information
                  transmitted to that network.
                </p>{" "}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">5. Your Rights.</p>
              <div className="ml-[11px] space-y-2">
                <p>
                  5.1 We will provide you with an opportunity to exercise
                  certain controls and choices regarding our collection, use and
                  sharing of your information through Opt-out options.
                </p>{" "}
                <p>
                  5.2 In accordance with the NDPR, the following are data
                  subject rights:
                </p>{" "}
                <p className="ml-16">
                  (a) You may wish to correct/rectification,
                </p>
                <p className="ml-16">(b) You may wish update,</p>
                <p className="ml-16">
                  (c) You may wish to delete/erase your account information,
                </p>
                <p className="ml-16">
                  (d) You may want to access your data at any time.
                </p>
                <p className="ml-16">
                  (e) You may change your choices for subscriptions;
                </p>
                <p>
                  5.3 Opt-out. We have created the following mechanisms to
                  provide you with control over certain uses and disclosures of
                  your information:
                </p>{" "}
                <div className="ml-5 space-y-2">
                  <div className="flex flex-col space-y-1">
                    <strong className="text-[#040405] font-semibold">
                      ● Tracking Technologies and Advertising.
                    </strong>
                    <p>
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when cookies are being sent. To
                      learn how you can manage your Flash cookie settings, visit
                      the Flash player settings page on Adobe's website. If you
                      disable or refuse cookies, please note that some parts of
                      this site may then be inaccessible or not function
                      properly.
                    </p>
                  </div>

                  <div className="flex flex-col space-y-1">
                    <strong className="text-[#040405] font-semibold">
                      ● Location Information.
                    </strong>
                    <p>
                      You can choose whether or not to allow the Service to
                      collect and use real-time information about your device’s
                      location through the device’s privacy settings. If you
                      block the use of location information, some parts of the
                      Service may then be inaccessible or not function properly.
                    </p>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <strong className="text-[#040405] font-semibold">
                      ● Promotion by the Company.
                    </strong>
                    <p>
                      If you do not wish to have your email address or contact
                      information used by the Company to promote our own or
                      third parties' products or services, you can opt-out by
                      following the unsubscribe instructions provided in the
                      e-mail you receive or contacting us directly at
                      admin@ajeoba.com. If we have sent you a promotional email,
                      you may send us a return email asking to be omitted from
                      future email distributions. This opt out does not apply to
                      information provided to the Company as a result of a
                      product purchase, warranty registration, product service
                      experience or other transactions.
                    </p>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <strong className="text-[#040405] font-semibold">
                      ● Disclosure of Your Information for Third-Party
                      Advertising and Marketing.
                    </strong>
                    <p>
                      If you do not want us to share your personal information
                      with unaffiliated or non-agent third parties for
                      advertising and marketing purposes, you can opt-out by
                      following the unsubscribe instructions provided in the
                      e-mail you receive or contacting us directly at
                      admin@ajeoba.com. You can also always opt-out by logging
                      into the Service and adjusting your user preferences in
                      your account profile by checking or unchecking the
                      relevant boxes or by sending us an email stating your
                      request to ajeoba@ajeoba.com.
                    </p>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <strong className="text-[#040405] font-semibold">
                      ● Targeted Advertising.
                    </strong>
                    <p>
                      If you do not want us to use information that we collect
                      or that you provide to us to deliver advertisements
                      according to our advertisers' target-audience preferences,
                      you can opt-out by following the unsubscribe instructions
                      provided in the email you receive or contacting us
                      directly at admin@ajeoba.com. You can also always adjust
                      your user advertising preferences in your account profile
                      by checking or unchecking the relevant boxes or by
                      providing us notice at admin@ajeoba.com. We do not control
                      third parties' collection or use of your information to
                      serve interest-based advertising. However, these third
                      parties may provide you with ways to choose not to have
                      your information collected or used in this way. You can
                      opt out of receiving targeted ads from members of the
                      Network Advertising Initiative (the “NAI”) by visiting
                      http://www.networkadvertising.org/index.asp.
                    </p>
                  </div>
                </div>
                <p>5.4 Accessing and Correcting Your Information.</p>{" "}
                <p className="ml-5">
                  If you have an account, you can help ensure that your contact
                  information and preferences are accurate, complete, and up to
                  date by logging into your account or by emailing us at
                  admin@ajeoba.com. For other personal information we hold, we
                  will provide you with access for any purpose including
                  requesting that we correct the data if it is inaccurate or
                  delete the data if we are not required to retain it by law or
                  for legitimate business purposes. We may request certain
                  personal information for the purposes of verifying the
                  identity of the individual seeking access to his/her personal
                  information records. We may decline to process requests that
                  are frivolous/vexatious, jeopardize the privacy of others, are
                  extremely impractical, or for which access is not otherwise
                  required by local law. If you delete your User Content from
                  the Service, copies of your User Content may remain viewable
                  in cached and archived pages, or might have been copied or
                  stored by other Service users. Proper access and use of
                  information provided on the Service, including User Content,
                  is governed by our terms of use https://ajeoba.com/legal.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                6. Retaining personal information.
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  6.1 This Section sets out our data retention policies and
                  procedure, which are designed to help ensure that we comply
                  with our legal obligations in relation to the retention and
                  deletion of personal information.
                </p>{" "}
                <p>
                  6.2 Personal information that we process for any purpose or
                  purposes may be maintained.
                </p>{" "}
                <p>
                  6.3 Notwithstanding the other provisions of this Section, we
                  will retain documents (including electronic documents)
                  containing personal data:
                </p>{" "}
                <p className="ml-16">
                  (a) to the extent that we are required to do so by law;
                </p>
                <p className="ml-16">
                  (b) if we believe that the documents may be relevant to any
                  ongoing or prospective legal proceedings; and
                </p>
                <p className="ml-16">
                  (c) in order to establish, exercise or defend our legal rights
                  (including providing information to others for the purposes of
                  fraud prevention and reducing credit risk).
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                7. Security of Personal Information.
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  7.1 We will take reasonable administrative, technical,
                  organizational and physical precautions to safeguard against
                  the loss, misuse or alteration of your personal information.
                  These safeguards vary based on the sensitivity of the
                  information that we collect and store. For example, when
                  processing sensitive personal data (such as data about ethnic
                  or racial origin, political opinions/union membership, data
                  concerning health, sex life or sexual orientation, genetic
                  data, or biometric data) or data that reveals sensitive
                  personal data, especially when using data profiling extra
                  safeguards will be added.
                </p>{" "}
                <p>
                  7.2 We will store all the personal information you provide on
                  our secure password- and firewall protected servers.
                </p>{" "}
                <p>
                  7.3 We will notify consumers and regulatory authorities in
                  case of breach without undue delay.
                </p>{" "}
                <p>
                  7.4 You acknowledge that the transmission of information over
                  the internet is inherently insecure, and any transmission of
                  personal information is at your own risk. We are not
                  responsible for circumvention of any privacy settings or
                  security measures we provide. Although we take appropriate
                  measures to safeguard against unauthorized disclosures of
                  information, we cannot assure you that information will never
                  be disclosed, altered or destroyed in a manner that is
                  inconsistent with this Privacy Policy.
                </p>{" "}
                <p>
                  7.5 You acknowledge that the safety and security of your
                  information also depends on you. You are responsible for
                  keeping the password you use for accessing the Service
                  confidential; we will not ask you for your password (except
                  when you log in to our Service).
                </p>{" "}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                8. Users from Other Jurisdictions.
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  8.1 The Service is controlled and operated by us from Nigeria.
                  If you are not a resident of Nigeria or you are located
                  outside Nigeria, you are not authorized to use the Service. If
                  you choose to use the Service or provide information to us,
                  please note that we may transfer the information, including
                  personal information, outside of Nigeria and process it there.
                </p>{" "}
                <p>
                  8.2 We do not represent or warrant that the Service, or any
                  portion thereof, are appropriate or available for use in any
                  particular jurisdiction.
                </p>{" "}
                <p>
                  8.3 Those who choose to access the Service do so on their own
                  initiative and at their own risk, and are responsible for
                  complying with all local laws, rules and regulations, and are
                  subject to United States export controls in connection with
                  your use of the Service and are responsible for any violations
                  of such controls, including, without limitation, any United
                  States embargoes or other federal rules and regulations
                  restricting exports.
                </p>{" "}
                <p>
                  8.4 We may limit the availability of the Service, in whole or
                  in part, to any person, geographic area or jurisdiction that
                  we choose, at any time and in our sole discretion.
                </p>{" "}
                <p>
                  8.5 Personal information that you publish on the Service or
                  submit for publication on our website may be available, via
                  the Internet, around the world. We cannot prevent the use or
                  misuse of such information by others.
                </p>{" "}
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                9. Our Contact Information.
              </p>
              <div className="ml-[11px] space-y-2">
                <p>
                  If you have any questions, feedback or to report a violation
                  regarding the Privacy Policy, you may email us
                  admin@ajeoba.com or you may send a letter to 13 Okotie Eboh
                  Street, Ikoyi, Lagos State, Nigeria.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
