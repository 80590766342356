import React, { useState } from "react";
import { iconImages } from "../utils/images";
import ValueOneItem from "./ValueOneItem";

const CoreValueOne = () => {
  const [startIndex, setStartIndex] = useState(0);

  const values = [
    {
      id: 1,
      icon: iconImages.Service,
      title: "Service & Delivery Excellence",
      desc: "Employ the best available human resources and technology to ensure a seamless experience with services, products and platforms.",
    },

    {
      id: 2,
      icon: iconImages.Customer,
      title: "Customer Value Creation",
      desc: "Our users are our raison d’être; we strive to create the best outcomes for our users with relevant, targeted and creative solution.",
    },

    {
      id: 3,
      icon: iconImages.Thinking,
      title: "Digital Thinking & Innovation",
      desc: "To constantly improve our services, leveraging digital platforms and world class technology.",
    },
  ];

  const handlePrev = () => {
    setStartIndex(Math.max(startIndex - 1, 0));
  };

  const handleNext = () => {
    setStartIndex(Math.min(startIndex + 1, values.length - 3));
  };

  const mobilePrev = () => {
    setStartIndex(Math.max(startIndex - 1, 0));
  };

  const mobileNext = () => {
    setStartIndex(Math.min(startIndex + 1, values.length - 1));
  };

  return (
    <div className="my-5 flex flex-col gap-8">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-4 lg:w-[600px]">
          <h3 className="text-[#130F26] font-bold text-2xl lg:text-4xl md:text-3xl lg:w-[75%] lg:text-left text-left lg:leading-[55px]">
            Ajeoba’s Core Values
          </h3>
          <p className="font-[inter] font-[300] lg:text-base lg:text-left text-xs md:text-sm">
            Our core values center on user-centric excellence, leveraging
            top-notch human resources and technology with unwavering commitment
            to ethics. We strive for continuous improvement through digital
            innovation and world-class technology.
          </p>
        </div>

        <div className="lg:flex items-center gap-5 hidden">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center bg-[#38DA711A] cursor-pointer"
            onClick={handlePrev}
          >
            <img src={iconImages.Prev} alt="" />
          </div>

          <div
            className="w-10 h-10 rounded-full flex items-center justify-center bg-[#006D33] cursor-pointer"
            onClick={handleNext}
          >
            <img src={iconImages.Next} alt="" />
          </div>
        </div>
      </div>

      <div className="overflow-x-hidden">
        <div className="hidden lg:flex flex-row md:hidden items-center gap-4 transition-transform duration-500 ease-in-out">
          {values.slice(startIndex, startIndex + 3).map((item, index) => (
            <ValueOneItem
              key={index}
              icon={item.icon}
              title={item.title}
              desc={item.desc}
            />
          ))}
        </div>

        <div className="flex lg:hidden md:flex flex-row items-center gap-4 transition-transform duration-500 ease-in-out">
          {values.slice(startIndex, startIndex + 1).map((item, index) => (
            <ValueOneItem
              key={index}
              icon={item.icon}
              title={item.title}
              desc={item.desc}
            />
          ))}
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row  gap-4">
        <ValueOneItem
          icon={iconImages.Service}
          title="Service & Delivery Excellence"
          desc="Employ the best available human resources and technology to ensure a
            seamless experience with services, products and platforms."
        />

        <ValueOneItem
          icon={iconImages.Customer}
          title="Customer Value Creation"
          desc="Our users are our raison d’être; we strive to create the best outcomes for our users with relevant, targeted and creative solution."
        />

        <ValueOneItem
          icon={iconImages.Thinking}
          title="Digital Thinking & Innovation"
          desc="To constantly improve our services, leveraging digital platforms and world class technology."
        />
      </div> */}

      <div className="lg:hidden items-center gap-5 flex mt-[-20px]">
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center bg-[#38DA711A] cursor-pointer"
          onClick={mobilePrev}
        >
          <img src={iconImages.Prev} alt="" />
        </div>

        <div
          className="w-10 h-10 rounded-full flex items-center justify-center bg-[#006D33] cursor-pointer"
          onClick={mobileNext}
        >
          <img src={iconImages.Next} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CoreValueOne;
