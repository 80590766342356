import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] lg:mb-24 lg:mt-[150px]">
        <div className="flex flex-col gap-5">
          <p className="text-[#040405] font-bold text-[30px] md:text-[40px] lg:text-4xl">
            Terms & Conditions
          </p>

          <div className="flex flex-col gap-3 font-[inter] font-[400] text-[10px] md:text-xs text-[#808080]">
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more- or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>

            <p>
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>

            <p>
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary, making this the first true
              generator on the Internet. It uses a dictionary of over 200 Latin
              words, combined with a handful of model sentence structures, to
              generate Lorem Ipsum which looks reasonable. The generated Lorem
              Ipsum is therefore always free from repetition, injected humour,
              or non-characteristic words etc.
            </p>
          </div>

          <div className="flex flex-col gap-3 font-[inter] font-[400] text-[10px] md:text-xs text-[#808080]">
            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">1. {""}Consent</p>
              <p className="ml-[11px]">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                2. {""}Information we collect
              </p>
              <p className="ml-[11px]">
                2.1. If you are going to use a passage of Lorem Ipsum:
              </p>
              <p className="ml-[11px]">
                2.1.1 All the Lorem Ipsum generators on the Internet tend to
                repeat predefined chunks as necessary, making this the first
                true generator on the Internet.
              </p>
              <p className="ml-[11px]">
                2.1.2 It uses a dictionary of over 200 Latin words, combined
                with a handful of model sentence structures, to generate Lorem
                Ipsum which looks reasonable. The generated Lorem Ipsum is
                therefore always free from repetition, injected humour, or
                non-characteristic words etc.
              </p>
              <p className="ml-[11px]">
                2.2. Many desktop publishing packages and web page editors now
                use Lorem Ipsum as their default model text, and a search for
                'lorem ipsum' will uncover many web sites still in their
                infancy. Various versions have evolved over the years, sometimes
                by accident, sometimes on purpose (injected humour and the
                like).
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">3. {""}Log Files</p>
              <p className="ml-[11px]">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet. It uses a dictionary of over 200
                Latin words, combined with a handful of model sentence
                structures, to generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition,
                injected humour, or non-characteristic words etc.
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                4. {""}Cookies and Web Beacons
              </p>
              <p className="ml-[11px]">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet. It uses a dictionary of over 200
                Latin words, combined with a handful of model sentence
                structures, to generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition,
                injected humour, or non-characteristic words etc.
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <p className="text-[#040405] font-semibold">
                5. {""}Children’s Information
              </p>
              <p className="ml-[11px]">
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary, making this the first true
                generator on the Internet. It uses a dictionary of over 200
                Latin words, combined with a handful of model sentence
                structures, to generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition,
                injected humour, or non-characteristic words etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default TermsAndConditions;
