import { iconImages } from "../utils/images";
import UpdatesItem from "./UpdatesItem";
import { useNavigate } from "react-router-dom";




const Updates = () => {

  const navigate = useNavigate();

  
  return (
    <div className="flex flex-col lg:mt-20 md:mt-5 lg:gap-4">
      <div className="mx-auto flex flex-col gap-3 text-center md:w-[450px]">
        <h3 className="text-[#006D33] font-bold text-2xl md:text-[30px]">
          Our Latest Updates
        </h3>
        <p className="text-[#141F39] text-sm md:text-base">
          Stay connected with Ajeoba's mission and vision for agricultural
          advancement.
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
      <UpdatesItem
            id={1}
            name={"Deborah"}
            date={"Oct 10th, 2023"}
            from="update"
            title="Unlocking Africa's Agricultural Potential: Market Trends and Analysis"
            description="The agricultural market in Africa is expected to reach a value of $1 trillion by 2025, signaling significant growth opportunities..."
            content={`
              The agricultural market in Africa is expected to reach a value of $1 trillion by 2025, signaling significant growth opportunities. At Ajeoba, we are dedicated to empowering smallholder farmers and consumers.

              Here are some of the key market trends:

              1. Increasing demand for organic and specialty crops.
              2. Rise in the use of digital agriculture technologies.
              3. Expansion of the market for climate-resilient agriculture.

              At Ajeoba, we focus on the following areas:
              ^ Conducting market research and analysis.
              ^ Providing training programs for farmers.
              ^ Ensuring access to high-quality inputs.
              ^ Developing digital agriculture platforms.
              ^ Implementing climate-resilient agriculture strategies.

              These initiatives benefit both farmers and consumers by leading to improved crop yields, enhanced food security, increased income, access to quality products, and sustainable agricultural practices.

              Don't miss out on these opportunities - join Ajeoba's community to stay informed about market trends and innovative solutions.
            `}
            icon={iconImages.Map}
          />

<UpdatesItem
            id={2}
            name={"Funmilola"}
            from="update"
            date={"Oct 14th, 2023"}
            title="Healthy Eating Made Easy: How to Choose Fresh, Sustainable Produce"
            description="As a health-conscious consumer, you should prioritize fresh, nutritious produce for your family. But with so many options available, how do you make informed choices..."
            content={`
              As a health-conscious consumer, you should prioritize fresh, nutritious produce for your family. But with so many options available, how do you make informed choices?

              Ajeoba can help you make informed choices, but before we delve into how Ajeoba can do this, what are the benefits of sustainable produce?

              1. Better taste and nutrition
              2. Reduced environmental impact
              3. Supporting local economies
              4. Improved food safety

              Now this is where Ajeoba's Solution comes in, our platform connects consumers with local farmers, ensuring; Freshness and quality, transparency and traceability, seasonal and diverse produce options, competitive pricing.

              Are you asking yourself why you should choose Ajeoba?

              1. We have a convenient online ordering
              2. Direct support for local farmers
              3. Access to expert advice on healthy eating
              4. Community engagement and education

              Join Ajeoba's community today and start enjoying fresh and sustainable produce!
              `}
            icon={iconImages.Agric}
          />

<UpdatesItem
            id={1}
            name={"Deborah"}
            date={"Oct 10th, 2023"}
            title="MAXIMISING CROP SEASONS"
            description="Have you noticed that some certain farm produce are very cheap at particulartimes in the year and are almost non existent or you’d have to pay premium..."
            content={`
              <div>
                <p class="mb-4">Have you noticed that some certain farm produce are very cheap at particular times in the year and are almost non existent or you'd have to pay premium prices to get them at other times? This is because each produce has its "season." In technical terms, it's the time of harvest of the produce.</p>
                
                <p class="mb-4">It would be advantageous if resources (funds and space) permit to take advantage of these seasons to stock up on these produce for storage. Some can be dried, while others can be canned (canning is a story for another day!). If properly handled and stored, these can stay in storage for at least 2 years.</p>
                
                <p class="mb-4">The purpose of this blog is to clue you in on the "seasons" for certain produce so you can take advantage of the surplus to stock up for when there are deficits in supply.</p>
                
                <table class="w-full border-collapse mb-4">
    <thead>
      <tr class="border-b">
        <th class="p-2 text-left">S/N</th>
        <th class="p-2 text-left">PRODUCE</th>
        <th class="p-2 text-left">SEASON</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b">
        <td class="p-2">1.</td>
        <td class="p-2">Onions</td>
        <td class="p-2">January - late February/Early March<br>August - Early October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">2.</td>
        <td class="p-2">Tomatoes</td>
        <td class="p-2">January - April<br>August - September</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">3.</td>
        <td class="p-2">Red bell peppers (Tatase)</td>
        <td class="p-2">January - Early March</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">4.</td>
        <td class="p-2">Scotch bonnets (rodo)</td>
        <td class="p-2">November - Early May</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">5.</td>
        <td class="p-2">Yam</td>
        <td class="p-2">September - December</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">6.</td>
        <td class="p-2">Egusi</td>
        <td class="p-2">July - September</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">7.</td>
        <td class="p-2">Beans</td>
        <td class="p-2">November - December</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">8.</td>
        <td class="p-2">Corn</td>
        <td class="p-2">September - November</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">9.</td>
        <td class="p-2">Groundnuts</td>
        <td class="p-2">September - October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">10.</td>
        <td class="p-2">Cashewnuts</td>
        <td class="p-2">January/February - May</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">11.</td>
        <td class="p-2">Palm oil</td>
        <td class="p-2">February - April</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">12.</td>
        <td class="p-2">Millet</td>
        <td class="p-2">September - October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">13.</td>
        <td class="p-2">Sorghum (Guinea corn)</td>
        <td class="p-2">December - January</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">14.</td>
        <td class="p-2">Ginger</td>
        <td class="p-2">November - January</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">15.</td>
        <td class="p-2">Soya beans</td>
        <td class="p-2">September - December</td>
      </tr>
    </tbody>
  </table>

                <p class="mb-4">In spite rising transportation costs that have negatively impacted prices, it is best to purchase produce when in season, as they would become even more unaffordable when they are out of season.</p>
                <p class="mb-4">Consideration for geographical zones should however be factored intostocking up, because harvest seasons are different for each zone as is plantingseason.</p>
                <p class="mb-4">Most important to ensuring you enjoy your bountiful purchase is to eitherensure to buy very dry produce or further dry them after purchase, or else rotand mold may occur and the salvageable quantity reduces bringing about lossof the proverbial gold.</p>
                <p class="mb-4">Our advice to you therefore is to follow the market to get information aboutavailability of these produce and take advantage of the season.</p>
              </div>
  `}
            icon={iconImages.Plant}
          />
      </div>

      <button onClick={() => navigate("/update")} className="px-5 py-3 md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-xl w-fit flex items-center gap-2 mx-auto text-sm md:text-base">
        <p> View More</p>
        <img src={iconImages.Arrow} alt="" className="" />
      </button>
    </div>
  );
};

export default Updates;
