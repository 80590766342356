import React from 'react';
import Nav from '../components/Nav';

const HelpCenter = () => {
  return (
    <div>
      <div className='ml-[120.5px]'>
        <Nav />
        <h2>Help Center Page</h2>
        <p>This is the Help Center page content.</p>
      </div>
    </div>
  );
};

export default HelpCenter;
