import React from "react";

const BenefitItem = ({icon, label}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="bg-[#F0F5EC] h-10 w-10 flex items-center justify-center rounded-md shadow-md">
        <img src={icon} alt="" />
      </div>
      <p className="font-bold text-[#141F39] text-sm">{label}</p>
    </div>
  );
};

export default BenefitItem;
