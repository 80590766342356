import React from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";
import { useState } from "react";

const Community = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log({ name, email, phone });
  };

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] md:my-10 lg:mb-24 lg:mt-[150px]">
        <div className="flex flex-col lg:flex-row items-center justify-between relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.Herobg} alt="" className="w-full h-full" />
          </div>

          <div className="flex flex-col gap-3 md:gap-5 lg:w-[50%] md:mt-10 lg:mt-[30px] ">
            <p className="text-[#141F39] font-bold text-[35px] leading-[40px] lg:leading-[50px] lg:text-4xl">
              Join the Ajeoba’s Community
            </p>
            <p className="w-[90%] font-[inter] text-[#4F4F4F] font-[300] lg:text-base text-xs md:text-sm md:w-full">
              Join the Ajeoba Community today and be part of a movement that is
              transforming agriculture through technology and collective effort.
              Together, we are cultivating a more sustainable and prosperous
              future for all.
            </p>
          </div>

          <div className="w-full lg:w-[50%] relative lg:left-7 mt-10 lg:mt-0">
            <div className="bg-white p-5 rounded-md">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                  onChange={(e) => setName(e.target.value)}
                />

                <input
                  type="email"
                  placeholder="Email"
                  className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  type="phone"
                  placeholder="Phone Number"
                  className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                  onChange={(e) => setPhone(e.target.value)}
                />

                <div className="flex items-center gap-6 md:mx-auto lg:mx-0">
                  <button className="px-5 py-[10px]  cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md text-sm md:text-base flex items-center gap-2">
                    <p className="text-sm">Submit Now</p>
                    <img src={iconImages.Arrow} alt="" className="w-3" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default Community;
