import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Privacy from "./pages/Privacy";
import Service from "./pages/Service";
import UpdateDetails from "./pages/UpdateDetails";
import Marketplace from "./pages/Marketplace";
import ComingSoon from "./pages/ComingSoon";
import HelpCenter from "./pages/HelpCenter";
import TermsAndConditions from "./pages/TermsAndConditions";
import Community from "./pages/Community";
import Update from "./pages/Update";
import Support from "./pages/Support";
import Guides from "./pages/Guides";
import GuideDetails from "./pages/GuideDetails";


const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Wrap Routes components around Route components */}
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/service" element={<Service />} />
        <Route path="/update" element={<Update />} />
        <Route path="/update/:id" element={<UpdateDetails />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/guides-resources/:id" element={<GuideDetails />} />
        <Route path="/guides-resources" element={<Guides />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/community" element={<Community />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />{" "}
        {/* This route will match if no other routes are matched */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
