import Digital from "../assets/images/icons/digital.svg";
import Supply from "../assets/images/icons/supply.svg";
import Finance from "../assets/images/icons/finance.svg";
import Market from "../assets/images/icons/market.svg";
import Invest from "../assets/images/icons/invest.svg";
import Data from "../assets/images/icons/data.svg";
import Arrow from "../assets/images/icons/arrow.svg";
import Explore from "../assets/images/icons/explore.svg";
import Benefits from "../assets/images/icons/benefits.svg";
import Profile from "../assets/images/icons/profile.svg";
import Coperate from "../assets/images/icons/coperate.svg";
import Quality from "../assets/images/icons/quality.svg";
import Wallet from "../assets/images/icons/wallet.svg";
import Logistics from "../assets/images/icons/logistics.svg";
import Extension from "../assets/images/icons/extension.svg";
import Input from "../assets/images/icons/input.svg";
import Arrowhead from "../assets/images/icons/arrowhead.svg";
import Dot from "../assets/images/icons/dot.svg";
import Plant from "../assets/images/icons/plant.png";
import Agric from "../assets/images/icons/agric.png";
import Seedlings from "../assets/images/icons/seedlings.png";
import Phones from "../assets/images/icons/phones.png";
import Google from "../assets/images/icons/playstore.svg";
import Apple from "../assets/images/icons/apple.svg";
import Logo from "../assets/images/icons/logo.svg";
import Phone from "../assets/images/icons/phone.svg";
import Email from "../assets/images/icons/email.svg";
import Location from "../assets/images/icons/location.svg";
import Insta from "../assets/images/icons/insta.svg";
import Twitter from "../assets/images/icons/twitter.svg";
import LinkedIn from "../assets/images/icons/linkedIn.svg";
import Herobg from "../assets/images/icons/herobg.svg";
import Menu from "../assets/images/icons/menu.svg";
import Close from "../assets/images/icons/close.svg";
import Prev from "../assets/images/icons/prev.svg";
import Next from "../assets/images/icons/next.svg";
import Quote from "../assets/images/icons/quote.svg";
import Timo from "../assets/images/icons/timo.svg";
import Mayowa from "../assets/images/icons/mayowa.svg";
import Star from "../assets/images/icons/star.svg";
import AboutPhone from "../assets/images/icons/aboutphone.svg";
import AboutGirl from "../assets/images/icons/aboutgirl.svg";
import Service from "../assets/images/icons/service.svg";
import Customer from "../assets/images/icons/customer.svg";
import Thinking from "../assets/images/icons/thinking.svg";
import Babatunde from "../assets/images/icons/babatunde.svg";
import Bukola from "../assets/images/icons/bukola.svg";
import Olusegun from "../assets/images/icons/olusegun.svg";
import About from "../assets/images/icons/aboutbg.svg";
import ArrowDown from "../assets/images/icons/arrowdown.svg";
import Filter from "../assets/images/icons/filter.svg";
import MarketSearch from "../assets/images/icons/marketsearch.svg";
import MarketArrow from "../assets/images/icons/marketarrow.svg";
import ProductImage from "../assets/images/icons/productimage.svg";
import Fullstar from "../assets/images/icons/fullstar.svg";
import Halfstar from "../assets/images/icons/halfstar.svg";
import Map from "../assets/images/icons/map.png";
import Notfound from "../assets/images/icons/notfound.png";
import Poster from "../assets/images/icons/poster.png";
import Inst from "../assets/images/icons/Inst.svg";
import Fb from "../assets/images/icons/Fb.svg";
import Tweet from "../assets/images/icons/Twiter.svg";
import Link from "../assets/images/icons/In.svg";
import Pattern from "../assets/images/icons/pattern.svg";
import Radial from "../assets/images/icons/radial.svg";
import Mega from "../assets/images/icons/mega.png";
import Rocket from "../assets/images/icons/rocket.png";
import Ring from "../assets/images/icons/ring.svg";
import Bow from "../assets/images/icons/bow.png";
import Add from "../assets/images/icons/add.svg";
import Times from "../assets/images/icons/times.svg";
import Soon from "../assets/images/icons/soon.svg";
import Signin from "../assets/images/icons/signIn.png";
import image1 from "../assets/images/Guides/image1.png";
import image2 from "../assets/images/Guides/image2.png";
import image3 from "../assets/images/Guides/image3.png";
import image4 from "../assets/images/Guides/image4.png";
import image5 from "../assets/images/Guides/image5.png";
import image6 from "../assets/images/Guides/image6.png";
import image7 from "../assets/images/Guides/image7.png";
import image8 from "../assets/images/Guides/image8.png";
import image9 from "../assets/images/Guides/image9.png";
import image10 from "../assets/images/Guides/image10.png";
import image11 from "../assets/images/Guides/image11.png";
import image12 from "../assets/images/Guides/image12.png";
import Yemi from "../assets/images/icons/yemi.png";
import Peter from "../assets/images/icons/peter.png";

export const iconImages = {
  Soon,
  Times,
  Add,
  Bow,
  Ring,
  Rocket,
  Mega,
  Radial,
  Pattern,
  Poster,
  Inst,
  Fb,
  Tweet,
  Link,
  Notfound,
  Map,
  ProductImage,
  Fullstar,
  Halfstar,
  MarketArrow,
  MarketSearch,
  Filter,
  ArrowDown,
  About,
  Babatunde,
  Bukola,
  Olusegun,
  Service,
  Customer,
  Thinking,
  AboutGirl,
  AboutPhone,
  Star,
  Timo,
  Mayowa,
  Quote,
  Prev,
  Next,
  Close,
  Menu,
  Herobg,
  Insta,
  Twitter,
  LinkedIn,
  Phone,
  Email,
  Location,
  Logo,
  Google,
  Apple,
  Phones,
  Agric,
  Seedlings,
  Dot,
  Plant,
  Arrowhead,
  Input,
  Extension,
  Logistics,
  Wallet,
  Quality,
  Coperate,
  Profile,
  Benefits,
  Explore,
  Arrow,
  Digital,
  Supply,
  Finance,
  Market,
  Invest,
  Data,
  Signin,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  Peter,
  Yemi,
};
