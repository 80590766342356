import React from "react";
import { iconImages } from "../utils/images";
import { useNavigate } from "react-router-dom";

const UpdatesItem = ({ title, description, icon, name, date, id, content, from }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-1 md:gap-3 hover:shadow-md cursor-pointer rounded-lg p-2">
      <div>
        <img src={icon} alt="" />
      </div>
      <div className="flex items-center gap-2 text-[#686868] text-sm">
        <p>{name}</p>
        <img src={iconImages.Dot} alt="" />
        <p>{date}</p>
      </div>

      <p className="text-[#003C1C] font-bold text-[15px] md:text-base">
        {title}
      </p>

      <p className="font-[inter] font-[300] text-xs md:text-sm">
        {description}
      </p>

      {
        from === "update" ? (
          <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() =>
            navigate(`/update/${id}`, {
              state: { title, description, icon, date, id, content, name },
            })
          }
        >
          <p className="text-[#006D33] font-semibold text-xs md:text-sm">
            Read More
          </p>
          <img src={iconImages.Arrowhead} alt="" className="" />
        </div>
        ) : (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() =>
              navigate(`/guides-resources/${id}`, {
              state: { title, description, icon, date, id, content, name },
            })
          }
        >
          <p className="text-[#006D33] font-semibold text-xs md:text-sm">
            Read More
          </p>
          <img src={iconImages.Arrowhead} alt="" className="" />
        </div>
        )
      }
    </div>
  );
};

export default UpdatesItem;
