import React from "react";
import Nav from "../components/Nav";
import { heroText } from "../utils/content";
import arrowIcon from "../assets/images/Home/Hero/arrow.png";
import { partners } from "../utils/content";
import Footer from "../components/Footer";
import Solutions from "../components/Solutions";
import { iconImages } from "../utils/images";
import Clients from "../components/Clients";
import Benefits from "../components/Benefits";
import Updates from "../components/Updates";
import Download from "../components/Download";
import { Link } from "react-router-dom";

const Home = () => {
  const partnersArray = Object.values(partners);

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mx-auto">
        <div className="flex flex-col lg:flex-row relative mt-24">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.Herobg} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col gap-2 lg:gap-5 md:mt-10 lg:mt-[50px]">
            <h2 className="lg:w-[584px] font-bold md:font-medium text-[30px] leading-[40px] md:text-[40px] lg:text-[50px] text-[#141F39] md:leading-[50px] lg:leading-[60px] lg:text-left w-full">
              {heroText.title}
            </h2>

            <p className="w-full lg:w-[500px] text-left lg:text-left text-[#4F4F4F] text-xs md:text-[16px] leading-[25px] ">
              {heroText.subTitle}
            </p>

            <div className="mt-2 lg:mt-0 flex items-center gap-6">
              <button className="px-5 py-[10px] md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md md:rounded-xl text-sm md:text-base">
                {heroText.btnLabel}
              </button>

              <Link to="/" className="flex items-center gap-3">
                <p className="text-xs md:text-base">Explore Products</p>
                <img src={arrowIcon} alt="" />
              </Link>
            </div>
          </div>

          <div className="md:w-[400px] lg:w-[553px] animate-pulse relative top-0 lg:right-10 mx-auto">
            <img src={heroText.HeroImg} alt="" />
          </div>
        </div>

        <div className="flex mt-10 md:mt-[100px]">
          {partnersArray.map((partner, index) => (
            <div className="w-[200px] md:w-[107px] md:mx-[14px] hover:shadow-lg transition-all duration-300 ease-in">
              <img
                key={index}
                src={partner}
                alt={`Partner ${index + 1}`}
                className="w-full"
              />
            </div>
          ))}
        </div>

        <Solutions />

        <div className="my-10 md:my-20 flex flex-col lg:flex-row items-center">
          <div className="lg:w-[600px] flex flex-col gap-2 lg:gap-5 md:w-full order-2 md:order-1">
            <h3 className="text-[#130F26] font-bold text-xl lg:text-4xl md:text-3xl lg:w-[95%] w-full lg:leading-[55px] lg:text-left mt-10 md:mt-0">
              Explore High Quality Farm Products And Inputs
            </h3>
            <p className="font-[inter] font-[300] lg:text-base lg:text-left text-xs md:text-sm">
              At our marketplace, we're not just selling farm products and
              inputs; we're offering you a pathway to seamless transactions
              within the value chain. We connect buyers and sellers to a robust
              and interactive B2B/B2C digital storefront for quality
              agricultural produce and inputs.
            </p>

            <div className="mt-2 lg:mt-0 relative w-fit lg:mb-0 mb-4">
              <button className="px-5 py-3 rounded-md md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative md:rounded-xl text-xs md:text-base flex items-center gap-2">
                Explore Products
                <img src={iconImages.Arrow} alt="" className="" />
              </button>
            </div>
          </div>

          <div className="order-1 md:order-2">
            <img
              src={iconImages.Explore}
              alt=""
              className="animate-pulse w-[250px] md:w-[350px]"
            />
          </div>
        </div>

        <Clients />

        <Benefits />

        <Updates />

        <Download />
      </div>
      <Footer />
    </>
  );
};

export default Home;
