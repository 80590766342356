import React from 'react';
import Nav from '../components/Nav';

const Service = () => {
  return (
    <div>
      <div className='ml-[120.5px]'>
        <Nav />
        <h2>Service Page</h2>
        <p>This is the Service page content.</p>
      </div>
    </div>
  );
};

export default Service;
