import React from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import { Link } from "react-router-dom";
import arrowIcon from "../assets/images/Home/Hero/arrow.png";
import CoreValueOne from "../components/CoreValueOne";
import Clients from "../components/Clients";
import CoreValueTwo from "../components/CoreValueTwo";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mt-24">
        <div className="flex flex-col lg:flex-row items-center justify-between relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.About} alt="" className="w-full h-full" />
          </div>
          <div className="w-[70%] lg:w-[50%] relative lg:left-7 order-2 lg:order-1 mt-10 lg:mt-0">
            <img
              src={iconImages.AboutPhone}
              className="w-[450px] animate-pulse"
              alt=""
            />
          </div>

          <div className="flex flex-col gap-2 lg:gap-5 lg:w-[50%] order-1 lg:order-2">
            <p className="text-[#141F39] font-bold text-[30px] md:text-[40px] lg:text-4xl">
              About Us
            </p>
            <p className="font-[inter] font-[300] lg:text-base text-left text-xs md:text-sm">
              Ajeoba is a digital infrastructure provider for investment
              agriculture, focused on bridging gaps in the agricultural value
              chain. Aligned with the Sustainable Development Goals, we're
              developing an ecosystem for agro-allied businesses, leveraging
              technology to replace inefficient physical infrastructure. Our
              platform aims to revolutionize the agricultural landscape in
              Africa, making processes more transparent, efficient, and
              accessible.
            </p>
            <div className="flex items-center gap-6 ">
              <button className="mt-2 lg:mt-0 px-5 py-[10px] md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md md:rounded-xl text-sm md:text-base">
                Get Started
              </button>

              <Link to="/" className="flex items-center gap-3">
                <p className="text-xs md:text-base">Explore Products</p>
                <img src={arrowIcon} alt="" />
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between my-10">
          <div className="flex flex-col gap-5 lg:w-[50%]">
            <p className="text-[#141F39] font-bold text-xl md:text-3xl lg:text-4xl">
              Our Mission & Vision
            </p>
            <p className="font-[inter] font-[300] lg:text-base text-left text-xs md:text-sm">
              To empower agro-allied businesses in Africa through innovative
              digital infrastructure, fostering efficiency, transparency, and
              sustainability across the agricultural value chain.
            </p>

            <p className="font-[inter] font-[300] lg:text-base text-left text-xs md:text-sm">
              To be the leading catalyst for agricultural transformation in
              Africa, leveraging technology to create a seamless and inclusive
              ecosystem that drives prosperity for farmers, businesses, and
              communities alike.
            </p>
          </div>

          <div className="w-[70%] lg:w-[50%] relative lg:left-7 mt-5 lg:mt-0">
            <img
              src={iconImages.AboutGirl}
              className="w-[450px] animate-pulse"
              alt=""
            />
          </div>
        </div>

        <CoreValueOne />

        <Clients />

        <CoreValueTwo />
      </div>
      <Footer />
    </>
  );
};

export default About;
