import { iconImages } from "../utils/images";
import SolutionItem from "./SolutionItem";

const Solutions = () => {
  return (
    <div className="flex flex-col mt-20 gap-4">
      <div className="mx-auto text-center md:w-[450px]">
        <h3 className="text-[#006D33] font-bold text-2xl md:text-[30px]">Our Solutions</h3>
        <p className="text-[#141F39] text-sm md:text-base">
          Unlocking Agricultural Potential Through Comprehensive Solutions
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
        <SolutionItem
          title="Digital Infrastructure"
          description="Ajeoba connects sellers and buyers in agriculture, using
              technology for efficient transactions, enhancing supply chains
              operations."
          icon={iconImages.Digital}
        />

        <SolutionItem
          title="Input Supply"
          description=" Ajeoba links farmers to quality inputs for improved crop health: organic fertilizers, seeds, pesticides, herbicides, and equipments."
          icon={iconImages.Supply}
        />

        <SolutionItem
          title="Financial Services"
          description="Ajeoba bridges financing gaps for Nigerian farmers, helping farmers gain access to small loans to boost agricultural process."
          icon={iconImages.Finance}
        />

        <SolutionItem
          title="Market Arbitrage"
          description="Creating a B2B2C marketplace for farmers, seamlessly linking them with off-takers and facilitating the sale of high-quality produce."
          icon={iconImages.Market}
        />

        <SolutionItem
          title="Proprietary Investment"
          description="Ajeoba is working to build a diversified investment portfolio within the agricultural sector, creating high-yield investment opportunities for investors."
          icon={iconImages.Invest}
        />

        <SolutionItem
          title="Intrinsic Database"
          description="Ajeoba addresses data scarcity in agriculture by creating a repository for predictive analysis, aiding farmers and others to make informed decisions."
          icon={iconImages.Data}
        />
      </div>
    </div>
  );
};

export default Solutions;
