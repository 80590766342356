// Nav
import { Link, useLocation } from "react-router-dom";
import { navLinks, logo } from "../utils/content";
import { useState, useEffect } from "react";
import { iconImages } from "../utils/images";

const Nav = () => {
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const [showBackground, setShowBackground] = useState(false);

  const TOP_OFFSET = 60;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`px-40 hidden  lg:flex items-center justify-between py-2 w-full fixed z-[1000] ${
          showBackground ? "bg-zinc-200 bg-opacity-90" : ""
        } top-0`}
      >
        <Link to={"/"}>
          <img src={logo} width={"102px"} height={"59.89"} alt="Logo" />
        </Link>

        <ul className={`flex relative`}>
          {navLinks.map((navLink) => (
            <li
              key={navLink.id}
              className={`text-[15px] flex-col pl-[32px] leading-[24px] hover:text-[#006D33] ${
                location.pathname === navLink.path ? "text-[#006D33]" : ""
              }`}
            >
              <Link to={navLink.path}>{navLink.title}</Link>
            </li>
          ))}
        </ul>

        <div className="btns flex items-center justify-center">
          <a href="https://web.ajeoba.com/" className="text-[#213344] text-[16px]">
            Log In
          </a>
          <a
            href="https://web.ajeoba.com/select-user-role"
            className="text-[#FBFDFD] left-[26px] bg-[#006D33] w-[124px] h-[48px] text-[16px] pr-3 pt-[13px] pl-8 rounded-lg relative font-medium"
          >
            Sign Up
          </a>
        </div>
      </nav>

      <nav
        className={`px-5 md:px-20 flex  lg:hidden items-center justify-between py-2 w-full fixed z-[1000] ${
          showBackground ? "bg-zinc-200 bg-opacity-90" : ""
        } top-0`}
      >
        <Link to={"/"}>
          <img src={logo} width={"90px"} height={"59.89"} alt="Logo" />
        </Link>

        <div onClick={() => setMenuOpen(true)}>
          <img src={iconImages.Menu} alt="memu" />
        </div>

        {menuOpen && (
          <ul
            className={`w-[70%] h-[100vh] absolute top-0 right-0 border bg-zinc-200 bg-opacity-100 flex flex-col gap-5 py-12 px-[32px]`}
          >
            <div
              className="absolute right-6 top-3 cursor-pointer"
              onClick={() => setMenuOpen(false)}
            >
              <img src={iconImages.Close} alt="" className="w-7" />
            </div>

            {navLinks.map((navLink) => (
              <li
                key={navLink.id}
                className={`text-[15px] flex-colpl-[32px] leading-[24px] hover:text-[#006D33] ${
                  location.pathname === navLink.path ? "text-[#006D33]" : ""
                }`}
              >
                <Link to={navLink.path} onClick={() => setMenuOpen(false)}>
                  {navLink.title}
                </Link>
              </li>
            ))}

            <div className="btns flex flex-col justify-center gap-5">
              <a href="/" className="text-[#213344] text-[16px]">
                Log In
              </a>
              <a
                href="/"
                className="text-[#FBFDFD] bg-[#006D33] px-3 py-2 w-fit text-[14px] rounded-md font-medium"
              >
                Sign Up
              </a>
            </div>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Nav;
