import React from "react";
import { iconImages } from "../utils/images";

const Product = () => {
  return (
    <div className="bg-white drop-shadow-[#006D331A] rounded-md lg:w-[220px] ">
      <div className="bg-[#E7F1F1] rounded-md flex flex-col items-center justify-center p-3">
        <img src={iconImages.ProductImage} alt="" className="w-32" />
      </div>

      <div className="flex flex-col gap-2 p-2">
        <p className="text-sm font-semibold text-[#040405] mt-2">
          Product Name
        </p>

        <div className="flex items-center gap-2 relative">
          <p className="text-[#006D33] text-sm font-semibold">4.5</p>
          <div className="flex items-center gap-1 absolute top-0 left-7">
            <img src={iconImages.Fullstar} alt="" className="" />
            <img src={iconImages.Fullstar} alt="" />
            <img src={iconImages.Fullstar} alt="" />
            <img src={iconImages.Fullstar} alt="" />
            <img src={iconImages.Halfstar} alt="" />

            <p className="text-[#686868] text-xs font-thin ml-2">(9,428)</p>
          </div>
        </div>
      </div>

      <div className="p-2">
        <button className="px-5 py-2 w-full cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md text-sm flex items-center justify-center">
          <p>Buy Now</p>
        </button>
      </div>
    </div>
  );
};

export default Product;
