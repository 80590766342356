import React from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";
import { useState } from "react";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log({ name, email, phone, message });
  };

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] md:my-10 lg:mb-24 lg:mt-[150px]">
        <div className="flex flex-col lg:flex-row items-center justify-between relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.Herobg} alt="" className="w-full h-full" />
          </div>

          <div className="flex flex-col gap-3 lg:w-[50%] md:mt-10 lg:mt-[30px]">
            <p className="text-[#141F39] font-bold text-[35px] leading-[40px] lg:leading-[50px] lg:text-4xl">
              Contact Us
            </p>
            <p className="w-[90%] font-[inter] text-[#4F4F4F] font-[300] lg:text-base text-xs md:text-sm md:w-full">
              "Get in touch with Ajeoba today. Whether you have questions,
              feedback, or partnership inquiries, our team is here to assist
              you. Reach out through our contact form or connect with us via
              email or phone. We look forward to hearing from you and supporting
              your agricultural endeavors.
            </p>

            <div className="flex flex-col">
              <h4 className="text-lg font-bold text-[#040405]">Phone</h4>
              <p className="text-[#808080] font-[500] text-sm">
                +234 9070534510
              </p>
            </div>

            <div className="flex flex-col">
              <h4 className="text-lg font-bold text-[#040405]">Email</h4>
              <p className="text-[#808080] font-[500] text-sm">
                admin@ajeoba.com
              </p>
            </div>

            <div className="flex flex-col">
              <h4 className="text-lg font-bold text-[#040405]">Address</h4>
              <p className="text-[#808080] font-[500] text-sm">
                13 Okotie Eboh Street, Ikoyi, Lagos State
              </p>
            </div>
          </div>

          <div className="w-full lg:w-[50%] relative lg:left-7 mt-10 lg:mt-0">
            <div className="bg-white p-5 rounded-md">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                  onChange={(e) => setName(e.target.value)}
                />

                <div className="flex items-center gap-2">
                  <input
                    type="phone"
                    placeholder="Phone Number"
                    className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                    onChange={(e) => setPhone(e.target.value)}
                  />

                  <input
                    type="email"
                    placeholder="Email"
                    className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <textarea
                  cols="30"
                  rows="8"
                  placeholder="Enter your message here"
                  className="px-5 py-2 rounded-md bg-[#FAFAFC] text-[#808080] focus:outline-none placeholder:text-sm w-full"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <div className="flex items-center gap-6 md:mx-auto lg:mx-0">
                  <button className="px-5 py-[10px]  cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md text-sm md:text-base flex items-center gap-2">
                    <p className="text-sm">Submit Now</p>
                    <img src={iconImages.Arrow} alt="" className="w-3" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default Contactus;
