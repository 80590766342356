import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { iconImages } from "../utils/images";
import UpdatesItem from "../components/UpdatesItem";


const Guides = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mt-24">
        <div className="flex flex-col w-full relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.About} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-center gap-5 lg:w-[55%] order-1 lg:order-2 mx-auto">
            <p className="text-[#141F39] font-bold text-[30px] md:text-[40px] lg:text-4xl">
              Our Guides and Resources
            </p>
            <p className="font-[inter] text-[#4F4F4F] font-[400]  text-xs md:text-sm text-center">
              Discover a wealth of knowledge with Ajeoba's comprehensive guides and resources. Access expert insights, practical tips, and valuable information tailored to support your agricultural endeavors and enhance your understanding of sustainable farming practices.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
          <UpdatesItem
            id={1}
            name={"Deborah"}
            date={"Oct 10th, 2023"}
            title="MAXIMISING CROP SEASONS"
            description="Have you noticed that some certain farm produce are very cheap at particulartimes in the year and are almost non existent or you’d have to pay premium..."
            content={`
              <div>
                <p class="mb-4">Have you noticed that some certain farm produce are very cheap at particular times in the year and are almost non existent or you'd have to pay premium prices to get them at other times? This is because each produce has its "season." In technical terms, it's the time of harvest of the produce.</p>
                
                <p class="mb-4">It would be advantageous if resources (funds and space) permit to take advantage of these seasons to stock up on these produce for storage. Some can be dried, while others can be canned (canning is a story for another day!). If properly handled and stored, these can stay in storage for at least 2 years.</p>
                
                <p class="mb-4">The purpose of this blog is to clue you in on the "seasons" for certain produce so you can take advantage of the surplus to stock up for when there are deficits in supply.</p>
                
                <table class="w-full border-collapse mb-4">
    <thead>
      <tr class="border-b">
        <th class="p-2 text-left">S/N</th>
        <th class="p-2 text-left">PRODUCE</th>
        <th class="p-2 text-left">SEASON</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-b">
        <td class="p-2">1.</td>
        <td class="p-2">Onions</td>
        <td class="p-2">January - late February/Early March<br>August - Early October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">2.</td>
        <td class="p-2">Tomatoes</td>
        <td class="p-2">January - April<br>August - September</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">3.</td>
        <td class="p-2">Red bell peppers (Tatase)</td>
        <td class="p-2">January - Early March</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">4.</td>
        <td class="p-2">Scotch bonnets (rodo)</td>
        <td class="p-2">November - Early May</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">5.</td>
        <td class="p-2">Yam</td>
        <td class="p-2">September - December</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">6.</td>
        <td class="p-2">Egusi</td>
        <td class="p-2">July - September</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">7.</td>
        <td class="p-2">Beans</td>
        <td class="p-2">November - December</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">8.</td>
        <td class="p-2">Corn</td>
        <td class="p-2">September - November</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">9.</td>
        <td class="p-2">Groundnuts</td>
        <td class="p-2">September - October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">10.</td>
        <td class="p-2">Cashewnuts</td>
        <td class="p-2">January/February - May</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">11.</td>
        <td class="p-2">Palm oil</td>
        <td class="p-2">February - April</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">12.</td>
        <td class="p-2">Millet</td>
        <td class="p-2">September - October</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">13.</td>
        <td class="p-2">Sorghum (Guinea corn)</td>
        <td class="p-2">December - January</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">14.</td>
        <td class="p-2">Ginger</td>
        <td class="p-2">November - January</td>
      </tr>
      <tr class="border-b">
        <td class="p-2">15.</td>
        <td class="p-2">Soya beans</td>
        <td class="p-2">September - December</td>
      </tr>
    </tbody>
  </table>

                <p class="mb-4">In spite rising transportation costs that have negatively impacted prices, it is best to purchase produce when in season, as they would become even more unaffordable when they are out of season.</p>
                <p class="mb-4">Consideration for geographical zones should however be factored intostocking up, because harvest seasons are different for each zone as is plantingseason.</p>
                <p class="mb-4">Most important to ensuring you enjoy your bountiful purchase is to eitherensure to buy very dry produce or further dry them after purchase, or else rotand mold may occur and the salvageable quantity reduces bringing about lossof the proverbial gold.</p>
                <p class="mb-4">Our advice to you therefore is to follow the market to get information aboutavailability of these produce and take advantage of the season.</p>
              </div>
  `}
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={2}
            name={"Ruth"}
            date={"Sept 3rd, 2024"}
            title="HOW TO SIGN UP, SIGN IN AND ONBOARD MEMBERS ON AJEOBA.COM"
            description="Learn how to easily sign up, sign in, and onboard new members on Ajeoba.com. Our step-by-step guide walks you through the process, from creating an account to verifying your information and accessing all the features of our platform."
            content={`
              <div class="p-4">
  <h1 class="text-2xl font-bold mb-4">HOW TO SIGN UP, SIGN IN AND ONBOARD MEMBERS ON AJEOBA.COM</h1>
  
  <h2 class="text-xl font-semibold mb-2">1. Sign Up Process</h2>

  <ol class="list-decimal pl-5 mb-4">
    <li class="mb-2">
      <strong>Visit the Website:</strong>
      <p>Open your web browser and go to <a href="https://www.ajeoba.com" class="text-blue-500 hover:underline">Ajeoba.com</a>.</p>
    </li>
      <img src="${iconImages.image1}" alt="Image 1" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Locate the Sign-Up Page:</strong>
      <p>Look for a “Sign Up” or “Register” button found at the top right corner of the homepage.</p>
    </li>

    <li class="mb-2">
      <strong>Select Persona:</strong>
      <p>Choose the persona that applies to you during sign-up.</p>
    </li>
      <img src="${iconImages.image2}" alt="Image 2" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Verify Your Email and Phone Number with OTP:</strong>
      <p>Check your email inbox and phone for a verification code.</p>
    </li>
      <img src="${iconImages.image3}" alt="Image 3" class="w-full h-auto mb-4" />
      <img src="${iconImages.image4}" alt="Image 4" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Set Up Your Password:</strong>
      <p>Choose a strong password for your account.</p>
    </li>
      <img src="${iconImages.image5}" alt="Image 5" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Complete Registration:</strong>
      <p>Fill out the required information and complete the KYC to finish registration.</p>
    </li>
      <img src="${iconImages.image6}" alt="Image 6" class="w-full h-auto mb-4" />
      <img src="${iconImages.image7}" alt="Image 7" class="w-full h-auto mb-4" />
      <img src="${iconImages.image8}" alt="Image 8" class="w-full h-auto mb-4" />
  </ol>

  <h2 class="text-xl font-semibold mb-2">2. Sign-In Process</h2>

  <ol class="list-decimal pl-5 mb-4">
    <li class="mb-2">
      <strong>Go to the Sign-In Page:</strong>
      <p>Navigate to the sign-in page on the website.</p>
    </li>

    <img src="${iconImages.image9}" alt="Image 9" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Enter Credentials:</strong>
      <p>Input your registered email address and password.</p>
    </li>

    <img src="${iconImages.image10}" alt="Image 10" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Access Your Account:</strong>
      <p>Click the “Sign In” button to access your account dashboard.</p>
    </li>
  </ol>

  <h2 class="text-xl font-semibold mb-2">3. Onboard Members</h2>

  <ol class="list-decimal pl-5 mb-4">
    <li class="mb-2">
      <strong>Go to the Farmer Page:</strong>
      <p>In your account, navigate to the farmer page.</p>
    </li>
    <img src="${iconImages.image11}" alt="Image 11" class="w-full h-auto mb-4" />

    <li class="mb-2">
      <strong>Upload the CSV File:</strong>
      <p>Use the bulk upload feature to onboard farmers by uploading a CSV file containing their details.</p>
    </li>
    <img src="${iconImages.image12}" alt="Image 12" class="w-full h-auto mb-4" />
  </ol>
              </div>
            `}
            icon={iconImages.Signin}
          />

          <UpdatesItem
            id={3}
            name={"Miracle"}
            date={"Oct 10th, 2024"}
            title="AGRICULTURE MADE EASY WITH AJEOBA"
            description="Welcome to Ajeoba Agro-Exchange, your partner in transforming African agriculture. We're bridging the gaps in the agricultural value chain, empowering farmers, and making..."
            icon={iconImages.Seedlings}
            content={`
              <div>
                <p class="mb-4">Welcome to Ajeoba Agro-Exchange, your partner in transforming African agriculture. We're bridging the gaps in the agricultural value chain, empowering farmers, and making agriculture easy, efficient, and profitable.</p>

                <p class="mb-4">African agriculture has faced numerous challenges,which include; limited market access, inefficient supply chain management, lack of transparency and accountability, insufficient funding and resources.</p>

                <p class="mb-4">We at Ajeoba Agro-Exchange has brought up an innovative digital platform that integrates the agriculture value chain, providing: market access, end-to-end supply chain management, access to financing and resources.</p>

                <p class="mb-4">Want to join us?</p>
                <ul class="list-disc pl-5 mb-4">
                  <li>Register on our platform</li>
                  <li>Create your profile (farmer, buyer, or supplier, input supplier, belong to a farmer association etc)</li>
                  <li>Explore our marketplace</li>
                  <li>Enjoy convenient delivery and logistics</li>
                </ul>

                <p class="mb-4">Join us today for a seamless experience.</p>
              </div>
              `}
          />

          {/*  <UpdatesItem
            id={4}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={5}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={6}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />

          <UpdatesItem
            id={7}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={8}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={9}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          /> */}
        </div>

        <button className="px-5 py-[10px] md:px-12 md:py-4 border border-[#006D33] w-fit flex items-center justify-center gap-2 mx-auto mb-10 rounded-lg focus:outline-none">
          <p className="text-[#006D33] font-semibold text-sm md:text-base">
            Load More
          </p>
          <img src={iconImages.MarketArrow} alt="" className="" />
        </button>
      </div>

      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
}

export default Guides