const SolutionItem = ({ title, description, icon }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 shadow-sm rounded-md bg-white p-5 lg:p-10 hover:shadow-xl transition-all duration-300 ease-out">
      <img src={icon} alt="" />
      <div className="flex flex-col items-center justify-center gap-5">
        <h4 className="font-bold text-[#141F39] text-center md:text-lg">
          {title}
        </h4>
        <p className="text-[#4F4F4F] text-center text-xs md:text-sm font-[inter] font-[300]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default SolutionItem;
