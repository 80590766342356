import React from "react";
import { iconImages } from "../utils/images";
import BenefitItem from "./BenefitItem";

const Benefits = () => {
  return (
    <div className="my-10  flex lg:flex-row flex-col items-center">
      <div className="md:w-[600px] flex flex-col gap-4">
        <h3 className="text-[#130F26] font-bold text-2xl lg:text-4xl md:text-3xl lg:w-[75%] lg:text-left text-left lg:leading-[55px]">
          Benefits from Ajeoba's Software?
        </h3>
        <p className="font-[inter] font-[300] lg:text-base text-xs md:text-sm">
          Our software facilitates connections between agricultural sellers and
          buyers, utilizing technology to streamline transactions and enhance
          supply chain efficiency.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <BenefitItem icon={iconImages.Profile} label="Farming Associations" />
          <BenefitItem icon={iconImages.Coperate} label="Corporate Buyers" />
          <BenefitItem icon={iconImages.Quality} label="Quality Assurance" />
          <BenefitItem icon={iconImages.Wallet} label="Financial Companies" />
          <BenefitItem
            icon={iconImages.Logistics}
            label="Logistics Companies"
          />
          <BenefitItem
            icon={iconImages.Extension}
            label="E-extension Officers"
          />
          <BenefitItem icon={iconImages.Profile} label="Input Suppliers" />

          <div className="flex items-center gap-2 cursor-pointer">
            <p className="text-[#006D33] font-semibold text-sm">View Details</p>
            <img src={iconImages.Arrowhead} alt="" />
          </div>
        </div>
      </div>

      <div>
        <img
          src={iconImages.Benefits}
          alt=""
          className="animate-pulse lg:w-[500px] w-[250px] md:w-[350px] mt-4 lg:mt-0"
        />
      </div>
    </div>
  );
};

export default Benefits;
